import logo from '../assets/new name logo.png';
import { useState, useEffect } from 'react';
import waitingWheel from '../assets/waiting wheel.gif';
import axios from 'axios'
import {createFFmpeg, fetchFile} from "@ffmpeg/ffmpeg";

const API_BASE_URL = '/compute/api'
const uploadFile = '/upload-file/'

const transcriptHistory = '/transcript-history/'
const getJustUploaded = '?pageNo=' + 1 + '&pageSize=' + 1 + '&search=';

const mimeType = 'video/webm; codecs=opus'; // video/webm
const outputExt = '.ogg';
const outputType = 'audio/ogg'

const timeslice = 60000;
const audioBitrate = 128000;
const videoBitrate = 315200;

const maxTime = 3 * 60 * 60 * 1000; // 3 * 60 * 60 * 1000;

const ffmpeg = createFFmpeg({log:false});

function Record() {

    const token = JSON.parse(localStorage.getItem('SSAuth')).token;

    const [choseTab, setChoseTab] = useState(false);
    const [finishedRecording, setFinishedRecording] = useState(false);
    const [uploaded, setUploaded ] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    const [initTime, setInitTime ] = useState('');
    const [timerMsg, setTimerMsg] = useState('');

    const [isConverting, setIsConverting] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    
    const load = async () => {
        if (!ffmpeg.isLoaded()) {
          await ffmpeg.load();
        }
    }
    
    useEffect(() => {
    load();
    }, []);

    // *** Record by choosing a tab, start
    const handleRecord = async () => {
        var chunks = [];
        var blob;
        var startTime;
        var localTimeStringStart;
        var stopTimer;

        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({video: true, audio:true});

            const recorder = new MediaRecorder (stream, {
                type: mimeType, 
                audioBitsPerSecond: audioBitrate,
                videoBitsPerSecond: videoBitrate
            });

            const reachedMaxTime = () => {
                if (recorder.state === 'recording') {
                    recorder.stop();

                    var tracks = stream.getTracks();
                    for( var i = 0 ; i < tracks.length ; i++ ) {
                        //console.log('removing a track');
                        //console.log(tracks[i]);    
                        tracks[i].stop();
                    };

                }
                setErrMsg('Your recording has reached the limit of 3 hours.')
            }

            const maxTimeTimer = setTimeout(reachedMaxTime, maxTime);

            recorder.ondataavailable = (e) => {
                chunks.push(e.data);
                //console.log(e);
                //console.log(chunks);
            }

            recorder.start(timeslice);

            recorder.onstart = () => {
                //console.log('recording started');
                //console.log(e);
                //console.log(JSON.stringify(e));
                startTime = new Date();
                localTimeStringStart = startTime.toLocaleTimeString([], {hour: 'numeric', minute:'numeric', second:'numeric', hour12: true});
                console.log('The time is now', localTimeStringStart);
                setInitTime(localTimeStringStart);
                setChoseTab(true);
                setUploaded(false);

            }
             
            stream.getVideoTracks()[0].addEventListener('ended', () => {
                //console.log('screensharing has ended');
                recorder.stop();
            })
            
            const reachedTimerTime = () => {
                if (recorder.state === 'recording') {
                    recorder.stop();

                    var tracks = stream.getTracks();
                    for( var i = 0 ; i < tracks.length ; i++ ) {
                        //console.log('removing a track');
                        //console.log(tracks[i]);    
                        tracks[i].stop();
                    };

                }
            }

            const timerButtom = document.getElementById('setTimer');

            timerButtom.addEventListener('click', () => {
                clearTimeout(stopTimer);
                const hoursString = document.getElementById('hours').value;
                const minutesString = document.getElementById('minutes').value;
                const hoursNum = Number(hoursString);
                const minutesNum = Number(minutesString)
                const stopAfter = hoursNum * 60 * 60 * 1000 + minutesNum * 60 * 1000;
                const stopTimeMS = startTime.getTime() + stopAfter;
                //const stopTime = new Date(stopTimeMS)
                //console.log('the stop time is ', stopTime.toLocaleTimeString([], {hour: 'numeric', minute:'numeric', second:'numeric', hour12: true}))
                const setTimeMS = new Date().getTime();

                const stopDelay = stopTimeMS - setTimeMS;

                if (stopDelay <= 0) {
                    setTimerMsg('Please try again at setting the timer.')
                } else {
                    stopTimer = setTimeout(reachedTimerTime, stopDelay);
                    setTimerMsg('Stopping '+hoursString+' hrs and '+minutesString+' mins after initialising.' )
                }

                
            })

            const finishButton = document.getElementById('finish');
            
            finishButton.addEventListener('click', () => {
                if (recorder.state === 'recording') {
                    recorder.stop();

                    var tracks = stream.getTracks();
                    for( var i = 0 ; i < tracks.length ; i++ ) {
                        //console.log('removing a track');
                        //console.log(tracks[i]);    
                        tracks[i].stop();
                    };

                }
            })
            
            recorder.onstop = () => {
                // console.log('The recording has ended or been stopped')
                clearTimeout(maxTimeTimer);
                clearTimeout(stopTimer);
                blob = new Blob(chunks, {type: mimeType}); // outside scope
                const url = URL.createObjectURL(blob); 
                document.getElementById('recordedVideo').src = url;
                chunks= [];

                var localTimeStringEnd = new Date().toLocaleTimeString([], {hour: 'numeric', minute:'numeric', second:'numeric', hour12: true});
                console.log('The time was', localTimeStringStart)
                console.log('The time is now', localTimeStringEnd);
                setFinishedRecording(true);
                setTimerMsg('');
            }


            // retrieving the record for the recording just uploaded
            const getRecJustUploaded = () => {
                axios.get(API_BASE_URL + transcriptHistory + getJustUploaded,
                {
                    headers: { 'Content-Type': 'application/json',
                    'Authorization': token
                    }
                }
                ).then(
                response => {
                    //console.log('got just uploaded')
                    //console.log(JSON.stringify(response?.data));
                    let justUploaded = response?.data.data.audio;
                    localStorage.setItem('recorderUploaded', JSON.stringify(justUploaded));         
                }, 
                reason => {
                    console.error(reason);
                }      
                ) 
            }

            // defining the submission of upload 
            const submitUpload = async (file) => {
            
                const formData = new FormData();
                formData.append('file', file);
        
                setIsUploading(true);
        
                axios.post(API_BASE_URL + uploadFile, formData, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100 ;
                    setUploadPercentage(progress);
                }
                }).then(
                response => {
                    console.log(JSON.stringify(response?.data));
                    getRecJustUploaded();
                    setUploadPercentage(0);
                    setIsUploading(false);
                }, 
                reason => {
                    console.error(reason);
                    setErrMsg('The upload failed. I recommend that you download as a video, then close this tab.')
                    setUploadPercentage(0);
                    setIsUploading(false);
                }
                );
            }
            
            // upload audio button
            const uploadAudioButton = document.getElementById('uploadAudio');

            uploadAudioButton.addEventListener('click', async () => {
                const fileNameNoExt = document.getElementById('recordingName').value.replaceAll('.', '');
                
                if (fileNameNoExt === '') {
                    window.alert('Please give your recording a name before uploading.');
                    setUploaded(false);
                    return;
                }

                setIsConverting(true);

                // write the file to memory
                try {
                    ffmpeg.FS('writeFile', 'video.webm', await fetchFile(blob));
                }
                catch (error) {
                    console.log(error)
                    setIsConverting(false);
                }
                    
                // run the FFMpeg command
                try {
                    await ffmpeg.run('-i', 'video.webm', '-vn', '-c:a', 'copy', 'audio.ogg');
                    const output = ffmpeg.FS('readFile', 'audio.ogg');
                    const outputRec = new File([new Blob([output.buffer], {type: outputType})], fileNameNoExt+outputExt, {type: outputType, lastModified: new Date().getTime()} );
                    submitUpload(outputRec);
                    setUploaded(true);
                }
                catch (error) {
                    console.log(error)
                } 
                finally {
                    setIsConverting(false);
                }

            })
            
            const downloadAudioButton = document.getElementById('downloadAudio');

            downloadAudioButton.addEventListener('click', async () => {   
                const fileNameNoExt = document.getElementById('recordingName').value.replaceAll('.', '');
                setIsConverting(true);

                // write the file to memory
                try {
                    ffmpeg.FS('writeFile', 'video.webm', await fetchFile(blob));
                }
                catch (error) {
                    console.log(error)
                    setIsConverting(false);
                }
                    
                // run the FFMpeg command
                try {
                    await ffmpeg.run('-i', 'video.webm', '-vn', '-c:a', 'copy', 'audio.ogg');
                    const output = ffmpeg.FS('readFile', 'audio.ogg');
                    const audioBlob = new Blob([output.buffer], {type: outputType});
                    const audioUrl = URL.createObjectURL(audioBlob);

                    const link = document.createElement('a');
                    link.download = fileNameNoExt;
                    link.href = audioUrl;
                    link.click();
                    window.URL.revokeObjectURL(audioUrl);
                }
                catch (error) {
                    console.log(error)
                } 
                finally {
                    setIsConverting(false);
                }
            })
            
        } catch (error) {
            console.log(error)
        }
    }
    // *** Record by choosing a tab, end

    // *** Recording time, start
    /*
    const hours = Math.floor(time / 36000);
    const minutes = Math.floor((time % 36000) / 600);
    const seconds = Math.floor((time % 600) / 10);

    const timeToDisplay = hours.toString().padStart(2,'0')+':'+minutes.toString().padStart(2,'0')+':'+seconds.toString().padStart(2,'0');
    */
    // *** Recording time, end

    // *** select options, start

    const hourOptions = [0,1,2];
    const minuteOptions = [0,5,10,15,20,25,30,35,40,45,50,55];

    // *** select options, end

    const handleFinish = () => {
        console.log('Finished recording');
        setFinishedRecording(true);
    }

    const handleDownloadVideo = () => {
        const downloadSrc = document.getElementById('recordedVideo').src;
        const link = document.createElement('a');
        link.download = document.getElementById('recordingName').value.replaceAll('.','');
        link.href = downloadSrc;
        link.click();
    }

    const handleReset = () => {
        //console.log('You have just hit the reset button')
        window.URL.revokeObjectURL(document.getElementById('recordedVideo').src);
        document.getElementById('recordedVideo').src = '';
        document.getElementById('recordingName').value = '';
        setChoseTab(false);
        setFinishedRecording(false);
        setInitTime('');
        setErrMsg('');
    }

    const handleMisc1 = () => {
        /*
        var timeNow = new Date();
        var localTimeStringNow = timeNow.toLocaleTimeString([], {hour: 'numeric', minute:'numeric', second:'numeric', hour12: true});
        console.log(localTimeStringNow);
        
        clearTimeout(miscTimeInt)
        miscTimeInt = setTimeout(timeAgainFn, 10000);
        */
        const minuteValue = Number(document.getElementById('minutes').value);
        console.log(minuteValue+1)
    }

    const handleMisc2 = () => {

        var timeNow = new Date();
        var localTimeStringNow = timeNow.toLocaleTimeString([], {hour: 'numeric', minute:'numeric', second:'numeric', hour12: true});
        console.log(localTimeStringNow);


    }

    return (
        <div className='record-page'>
            <header className='record-header'>
                    <img alt='Saver Scribe' src={logo} className='logo-small'/>
                    <br /> <br />
            </header>
            <div hidden={!isConverting}><span>Extracting audio <img alt='please wait' src={waitingWheel} className='waiting-wheel'/></span></div> 
            <div hidden={!isUploading} ><span>Uploading audio <img alt='please wait' src={waitingWheel} className='waiting-wheel'/> </span>{ (uploadPercentage > 0) ? ( <span>Progress: {Math.round(uploadPercentage)}%</span> ) : (null) }</div>            
            <div className='error-msg' hidden={(errMsg==='')}>{errMsg}</div>
            <div className='record-page-text'>
                <h3>1. Open recording in a new tab</h3>
                <h3>2. Click to choose a tab to record, and play your recording</h3> 
                <button className='btn btn-choose-tab' onClick={handleRecord} disabled={choseTab}>
                    Choose a tab
                </button> 
                <div hidden={!choseTab}><small>(Hint: right-click on a tab to mute.)</small></div> 
                <div hidden={!choseTab}>
                    <div className='recorder-progress' hidden={finishedRecording}><span className='recorder-red'>&#x25C9; </span><span>Recording in progress, initialised at: {initTime}</span></div>
                    <div className='recorder-progress' hidden={finishedRecording}><span>Set a timer to stop recording this long after initialising: </span> 
                        <select id='hours'>
                            {hourOptions.map((hourOption)=> (
                                <option key={hourOption} value={hourOption}>{hourOption}</option>
                            ))}
                        </select> 
                        <span> hrs </span> 
                        <select id='minutes'>
                            {minuteOptions.map((minuteOption)=> (
                                <option key={minuteOption} value={minuteOption}>{minuteOption}</option>
                            ))}    
                        </select>
                        <span> mins </span>
                        <button id='setTimer'>Set timer</button> 
                        <span> {timerMsg}</span>
                    </div>
                    <div className='recorder-progress' hidden={!finishedRecording}>Recording complete</div>
                    <div className='recorder-pause-finish-group'>
                        <div className='recorder-label-group'>
                            <button className='btn recorder-finish-btn' onClick={handleFinish} disabled={finishedRecording} id='finish'>
                                {'\u23f9'}
                            </button> <br/>
                            <div><strong><small>Finish recording</small></strong></div>
                        </div>
                    </div> 
                    <div><small>(Warning: the recording stops when your computer goes to sleep.)</small></div>
                </div>
                <h3>3. Download recording</h3>
                <div hidden={!finishedRecording}>
                    <video id='recordedVideo' controls width='600' /> <br />
                    <div>
                        <span>Recording name: </span>
                        <input
                            type='text'
                            id='recordingName'
                            autoComplete='off'
                            maxLength={255}
                            className='recorder-name-input'
                        />
                    </div>
                    <button className='btn recorder-upload' id='uploadAudio' disabled={uploaded} hidden={true}>Upload and transcribe</button>
                    <button className='btn recorder-download' id='downloadAudio' hidden={true}>Download audio</button>
                    <button className='btn recorder-download' onClick={handleDownloadVideo}>Download video</button> <br />
                    <div><small>You can find downloaded recordings in your Downloads folder.</small></div>
                    <br /> 
                    <button className='recorder-reset' onClick={handleReset} hidden={true}>Reset</button>
                    <div><span>Your recording is complete. You can now close this tab or refresh the page: <button className='btn recorder-download' onClick={() => window.location.reload()}>Refresh</button></span></div>
                </div>
                <button onClick={handleMisc1} id='misc1' hidden={true}>Misc1</button>
                <button onClick={handleMisc2} id='misc2' hidden={true}>Misc2</button>
            </div>
        </div>
    )

}

export default Record;
