import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Signup from './pages/Signup';
import Error from './pages/Error';
import ForgotPassword from './pages/ForgotPassword';
import Edit from './pages/Edit';
import Streamed from './pages/Streamed';
import Record from './pages/Record';

function App() {

  const Protected = ({children}) => {
    if (localStorage.getItem('SSAuth') === null) {
      return <Navigate to='/login' replace/>
    }
    return children
  }

  const Index = () => {
    if (localStorage.getItem('SSAuth') === null) {
      return <Navigate to='/login' replace/>
    }
    return <Navigate to='/home' replace/>
  }

  const EditOnly = () => {
    if (sessionStorage.getItem('transcriptData') === null) {
      return <Navigate to='/error' replace />
    }
    return <Edit />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='login' element={<Login />} />
        <Route path='home' element={
          <Protected >
            <Home />
          </Protected>
          } />
        <Route path='signup' element={<Signup />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='streamed' element={
          <Protected >
            <Streamed />
          </Protected>
          } />
        <Route path='record' element={
          <Protected >
            <Record />
          </Protected>
          } />
        <Route path='edit' element={<EditOnly />} />
        <Route path='error' element={<Error />} />
        <Route path='*' element={<Navigate to='/error' />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
