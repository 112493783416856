import { Link } from 'react-router-dom';
import logo from '../assets/new name logo.png';
import { useState, useEffect } from 'react';
import axios from 'axios'

export const API_BASE_URL = '/compute/api'

const forgotPassword = '/forgot-password/'

const ForgotPassword = () => {

  const [email, setEmail] = useState('');
  
  const [errMsg, setErrMsg] = useState('');
  const [wait, setWait] = useState(false);  
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setErrMsg('')
  }, [email])

  const handleSubmit = async (e) => {
    e.preventDefault();

    setWait(true);
    
    axios.post(API_BASE_URL + forgotPassword,
        JSON.stringify({email}),
        {
            headers: { 'Content-Type': 'application/json' },
        }
    ).then(
        response => {
            console.log(JSON.stringify(response?.data));
            setWait(false);

            if (response?.data.status === 200) {
                setSuccess(true);
            } else if (response?.data.status === 404) { 
              setErrMsg('Email address not found');
            } else {
                setErrMsg((JSON.stringify(response?.data.message)).replace(/^"(.*)"$/, '$1'));
            }
        },
        reason => {
        console.error(reason);
        setErrMsg('Login failed');
        }
    )
}

  return (
    <div className='signup-page'>
      <header className='signup-header'>
        <img alt='Saver Scribe' src={logo} className='logo-small'/>
      </header>
      <div>
        <h2>Forgot your password?</h2>
        <span>Enter your email to receive a password reset link</span>
      </div>
      <form onSubmit={handleSubmit}>
          <div>
              <label htmlFor='email' className='form-label'>Email:</label>
          </div>
          <div>
            <input
                type='email'
                id='email'
                autoComplete='off'
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className='form-input'
            />
          </div>
          <div>
            <button disabled={(wait || success) ? true : false} className='btn btn-login'>
                  Send reset link
            </button>
          </div>
      </form>
      <p>
        <Link to='/login'>
            Return to login
        </Link>
      </p>
      {wait? (
        <p>Waiting for confirmation</p>
        ) : (
          null
        ) 
      }
      {success? (
        <p>Check your email to find a password reset link. <br/>(You may need to check your spam folder.)</p>
        ) : (
          null
        ) 
      }
      <p className='error-msg'>{errMsg}</p>
    </div>
  );  

  }
  export default ForgotPassword;