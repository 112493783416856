import {  useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import logo from '../assets/new name logo.png';
import waitingWheel from '../assets/waiting wheel.gif';
import axios from 'axios'
import AuthContext from '../context/AuthProvider';
import DisplayBoxes from '../components/DisplayBoxes';
import {createFFmpeg, fetchFile} from "@ffmpeg/ffmpeg";
import InfoGuide from '../components/InfoGuide';
import WhatsNew from '../components/WhatsNew';

const API_BASE_URL = '/compute/api'
const uploadFile = '/upload-file/'

const transcriptHistory = '/transcript-history/'
const getAllUploaded = '?pageNo=' + 1 + '&pageSize=' + 999 + '&search=';
const getJustUploaded = '?pageNo=' + 1 + '&pageSize=' + 1 + '&search=';
const transcriptList = '/transcript/';
const audioId = '?audioId=';

const initialLoadNo = 10;
const loadMoreNo = 10;

const maxFileSize = 2 * 2**30; 

const ffmpeg = createFFmpeg({log:false});

const audioFormats = '.3ga, .8svx, .aac, .ac3, .aif, .amr, .au, .dss, .flv, .m4a, .m4b, .m4p, .m4r, .mp3, .mpga, .ogg, .oga, .mogg, .opus, .qcp, .tta, .voc, .wma, .wv,';
const videoFormats = '.mp4, .mov, .webm,';

const extTable = {
  '.mp4': ['.m4a', 'audio/x-m4a'],
  '.mov': ['.m4a', 'audio/x-m4a'],
  '.webm': ['.ogg', 'audio/ogg'],
}

const lookUpExtTable = (audioExt, columnNo) => extTable[audioExt][columnNo] || 'not found';

function Home() {

  const token = JSON.parse(localStorage.getItem('SSAuth')).token;

  const {uploadedList, setUploadedList, displayList, setDisplayList, noDisplayed, setNoDisplayed} = useContext(AuthContext);

  const [errMsg, setErrMsg] = useState('');

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isConverting, setIsConverting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [openInfo, setOpenInfo] = useState(false);
  const [openNew, setOpenNew] = useState(false);

  const navigate = useNavigate();
  
  const load = async () => {
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }
  }

  useEffect(() => {
    load();
  }, [])
  
  useEffect(() => {
    sessionStorage.removeItem('transcriptData');
  }, [])

  useEffect(() => {
    initializeUploadedList();
  }, []);
  
  useEffect(() => {
    if (localStorage.getItem('seenInfo') === null) {
      localStorage.setItem('seenInfo', true);
      setOpenInfo(true);
    }

    if (localStorage.getItem('new20230517') === null) {

      var keyArr = []; 
      for (let i = 0; i < localStorage.length; i++){
          if (localStorage.key(i).substring(0,3) === 'new') {
              keyArr.push(localStorage.key(i));
          }
      }

      for (let i = 0; i < keyArr.length; i++) {
          localStorage.removeItem(keyArr[i]);
      }

      localStorage.setItem('new20230517', true);
      setOpenNew(true);
    }

  }, [])
  
  useEffect(() => {
    setNoDisplayed(displayList.length); 
  }, [displayList]);

  //*** check progress interval start */
  useEffect(() => {
    const progressInterval = setInterval(() => {
      const inProgressIndex = displayList.findLastIndex(rec => rec.status === 1);
      //console.log('the progress index is:');
      //console.log(inProgressIndex);

      if (inProgressIndex >= 0) {
        const getInProgress = '?pageNo=' + 1 + '&pageSize=' + (inProgressIndex + 1) + '&search=';

        axios.get(API_BASE_URL + transcriptHistory + getInProgress,
          {
            headers: { 'Content-Type': 'application/json',
            'Authorization': token
            }
          }
        ).then(
          response => {
            let responseArray = response?.data.data.audio;  
            //console.log(JSON.stringify(responseArray));

            const displayListToLoop = displayList;

            for (const recDisplayed of displayListToLoop) {
              if (recDisplayed.status === 1) {
                  const foundInResponse = responseArray.find( recResponse => recResponse.id === recDisplayed.id && recResponse.status === 2 );
                  
                  if (foundInResponse !== undefined) {
                      recDisplayed.status = 2;
                      recDisplayed.transcriptName = foundInResponse.transcriptName;
                  }
                  
              } 
            }
            setDisplayList(displayListToLoop);
            setUploadedList([...displayListToLoop,...uploadedList.slice(noDisplayed)]);
          }, 
          reason => {
            console.error(reason);
          }      
        )  
      }
    }, 30000);
    return () => clearInterval(progressInterval);
  }, [uploadedList, displayList, noDisplayed])
  //*** check progress interval ends */
  
  // *** get initial list of uploaded recordings, start
  const initializeUploadedList = () => {
    //console.log('The length of the upLoadedList is:')
    //console.log(uploadedList.length);
    if (uploadedList.length === 0) {
      axios.get(API_BASE_URL + transcriptHistory + getAllUploaded,
        {
          headers: { 'Content-Type': 'application/json',
          'Authorization': token
          }
        }
      ).then(
        response => {
          //console.log('the response is:');
          //console.log(JSON.stringify(response?.data));
          let responseAudio = response?.data.data.audio;
          //console.log(responseAudio);
          setUploadedList(responseAudio);
          setDisplayList(responseAudio.slice(0, initialLoadNo));
          setNoDisplayed(Math.min(responseAudio.length, initialLoadNo));          
        }, 
        reason => {
          console.error(reason);
          setErrMsg('Failed to retrieve recordings');
        }      
      ) 
    }
  }
    
  // *** get initial list of uploaded recordings, end

  // *** check if the recorder has uploaded a recording, start
  useEffect(() => {

    const onRecordedUploaded = (e) => {
      const { key, newValue } = e;
      if (key === "recorderUploaded") {
        let justUploaded = JSON.parse(newValue);
        setUploadedList([...justUploaded,...uploadedList]);
        setDisplayList([...justUploaded,...displayList]);  
      }
    }

    window.addEventListener('storage', onRecordedUploaded);

    return () => {
      window.removeEventListener('storage', onRecordedUploaded)
    }
  }, [uploadedList, displayList, noDisplayed])
  // *** check if the recorder has uploaded a recording, end

  const handleLogout = () => {
    localStorage.removeItem('SSAuth');
    setUploadedList([]);
    setDisplayList([]);
    setNoDisplayed();
    navigate('/login');
  }

  // *** handle upload, start  
  const handleUpload = (e) => {
    const uploadedRec = e.target.files[0];
    setErrMsg('');
    setIsConverting(false);

    // check file size
    if (uploadedRec.size > maxFileSize) {
      var isAcceptableSize = false;
      setErrMsg('File must be smaller than 2GB');
    } else {
      var isAcceptableSize = true;
    }

    // what is its html type? 
    const uploadedRecType = uploadedRec.type;

    // can I send it without converting?
    if ( uploadedRecType.includes('audio') )  {
      var canSubmitImmediately = true;
    } else {
      var canSubmitImmediately = false;
    }      
    
    // retrieving the record for the recording just uploaded
      const getRecJustUploaded = () => {
        axios.get(API_BASE_URL + transcriptHistory + getJustUploaded,
          {
            headers: { 'Content-Type': 'application/json',
            'Authorization': token
            }
          }
        ).then(
          response => {
            //console.log(JSON.stringify(response?.data));
            let justUploaded = response?.data.data.audio;
            setUploadedList([...justUploaded,...uploadedList]);
            setDisplayList([...justUploaded,...displayList]);           
          }, 
          reason => {
            console.error(reason);
            setErrMsg('Failed to update recordings');
          }      
        ) 
      }

    // defining the submission of upload 
    const submitUpload = async (file) => {
      
      const formData = new FormData();
      formData.append('file', file);

      setIsUploading(true);

      axios.post(API_BASE_URL + uploadFile, formData, {
        headers: {
          'Authorization': token,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100 ;
          setUploadPercentage(progress);
        }
      }).then(
        response => {
          //console.log(JSON.stringify(response?.data));
          getRecJustUploaded();
          setUploadPercentage(0);
          setIsUploading(false);
        }, 
        reason => {
          console.error(reason);
          setErrMsg('Upload failed');
          setUploadPercentage(0);
          setIsUploading(false);
        }
      );
    }
    
    // defining the conversion
    const convertAndSubmit = async (file) => {
      setIsConverting(true);
      var fileName = file.name;
      var fileNameNoExt = fileName.substring(0,fileName.lastIndexOf('.'));
      var fileExt = fileName.substr(fileName.lastIndexOf('.'));
      var outputExt = lookUpExtTable(fileExt, 0);
      var outputType = lookUpExtTable(fileExt, 1);

      //console.log(outputExt);
      //console.log(outputType);
      
      // write the file to memory
      try {
        ffmpeg.FS('writeFile', fileName, await fetchFile(file));
      }
      catch {
        setErrMsg('Could not store recording in memory');
        setIsConverting(false);
      }

      // run the FFMpeg command
      try {
        await ffmpeg.run('-i', fileName, '-vn', '-c:a', 'copy', fileNameNoExt+outputExt);
        const output = ffmpeg.FS('readFile', fileNameNoExt+outputExt);
        const outputRec = new File([new Blob([output.buffer], {type: outputType})], fileNameNoExt+outputExt, {type: outputType, lastModified: new Date().getTime()} );
      
        //console.log('the conversion has finished, and the output is called:');
        //console.log(outputRec.name);
        //console.log('the output size in MB is:');
        //console.log(outputRec.size/2**20);

        submitUpload(outputRec);
      }
      catch {
        setErrMsg('Could not convert recording')
      } 
      finally {
        setIsConverting(false);
      }
    }
    
    // now, the function to deal with the upload
    if (isAcceptableSize) {
      if (canSubmitImmediately) {
        submitUpload(uploadedRec);
      } else {
        convertAndSubmit(uploadedRec);
      }
    }
  }
  // *** handle upload, end

  const handleStreamed = () => {
    window.open('/streamed', '_blank')
  }

  const handleLoadMore = () => {
    const moreRecordings = uploadedList.slice(noDisplayed, noDisplayed + loadMoreNo);
    setDisplayList([...displayList,...moreRecordings]);
    // Recall the setNoDisplayed in the UseEffect above
  }

  // handling deletion
  const confirmDelete = (event, id) => {
    if(window.confirm('Are you sure you want to delete?')) {
      handleDelete(id);
    }
    event.stopPropagation();
  }

  const handleDelete = (id) => {
    const updatedDisplayList = displayList.filter((item) => item.id !== id);
    const undisplayedList = uploadedList.slice(noDisplayed);
    setDisplayList(updatedDisplayList);
    setUploadedList([...updatedDisplayList,...undisplayedList]);

    axios.delete(API_BASE_URL + transcriptList + audioId + id, 
      {
        headers: {'Content-Type': 'application/json',
        'Authorization': token
        }
      }
    ).then(
      response => {
        //console.log(response)
      },
      reason => {
        console.error(reason);
        setErrMsg('Failed to delete from database')
      }
    )
  }

  // handling select 
  const handleSelectRec = (data) => {
    sessionStorage.setItem('transcriptData', JSON.stringify({ id: data.id, fileName: data.fileName, generatedName: data.generatedName, transcriptName: data.transcriptName }))
    navigate('/edit');
  }

  // handling what's new
  const handleClickOpenNew = () => {
    setOpenNew(true);
  }

  const handleCloseNew = () => {
    setOpenNew(false);
  }

  // handling info guide
  const handleClickOpenInfo = () => {
    setOpenInfo(true);
  }

  const handleCloseInfo = () => {
    setOpenInfo(false);
  }

  // ** the return begins here
    return (
      <div className='home-page'>
        <div className='top-right'>
          <button className='btn btn-info' onClick={handleClickOpenNew} >
            <strong>What's new?</strong>
          </button>
          <button className='btn btn-info' onClick={handleClickOpenInfo} >
            <strong>Info</strong>
          </button>
          <button className='btn btn-logout' onClick={handleLogout}>
            Log out
          </button>
        </div>
        <header>
          <img alt='Saver Scribe' src={logo} className='logo-small'/>
        </header>
        <div>
          <span><br/></span>
          <span><strong>Upload video or audio file: </strong></span>
          <input type='file' accept={audioFormats+videoFormats} onChange={handleUpload}/> <br />
          <span><strong>Are your recordings streamed, with no download button? </strong></span>
          <button className='btn btn-streamed' onClick={handleStreamed}>Click here</button>
        </div>
        <div className='error-msg' hidden={(errMsg==='')}>{errMsg}</div>
        <div hidden={!isConverting}><span>Extracting audio <img alt='please wait' src={waitingWheel} className='waiting-wheel'/></span></div> 
        <div hidden={!isUploading} ><span>Uploading audio <img alt='please wait' src={waitingWheel} className='waiting-wheel'/> </span>{ (uploadPercentage > 0) ? ( <span>Progress: {Math.round(uploadPercentage)}%</span> ) : (null) }</div>
        <DisplayBoxes displayListToRender={displayList} deleteFunction={confirmDelete} selectRecFunction={handleSelectRec}/>
        <button className='btn btn-load-more' disabled={(noDisplayed >= uploadedList.length) ? true : false} onClick={handleLoadMore}>Load more</button>
        <WhatsNew open={openNew} handleClose={handleCloseNew} />
        <InfoGuide open={openInfo} handleClose={handleCloseInfo} />
      </div>
    );
  }
  
  export default Home;
