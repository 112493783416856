import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/new name logo.png';
import { useState, useEffect } from 'react';
import axios from 'axios';

export const API_BASE_URL = '/compute/api'

const login = '/login/'

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [wait, setWait] = useState(false);  
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setErrMsg('')
    }, [email, password])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setWait(true);
        
        axios.post(API_BASE_URL + login,
            JSON.stringify({email,password}),
            {
                headers: { 'Content-Type': 'application/json' },
            }
        ).then(
            response => {
                //console.log('a user has logged in');
                //console.log('the response is:');
                //console.log(JSON.stringify(response?.data));
                setWait(false);

                if (response?.data.status === 200) {
                    setSuccess(true);
                    
                    const token = response?.data.data.token;  
                    //console.log('... and the token is:');
                    //console.log(JSON.stringify(token));
                    localStorage.setItem('SSAuth', JSON.stringify({email, token}));

                    navigate('/home');
                } else if (response?.data.status === 401) { 
                    setErrMsg('Password incorrect');
                } else if (response?.data.status === 404) { 
                    setErrMsg('Email address not found');
                } else {
                    setErrMsg((JSON.stringify(response?.data.message)).replace(/^"(.*)"$/, '$1'));
                }
            },
            reason => {
            console.error(reason);
            setErrMsg('Login failed');
            }
        )
    }

    return (
      <div className='login-page'>
        <header className='login-header'>
            <img alt='Saver Scribe' src={logo} className='logo'/>
        </header>
        <div className='limited-time'><br/>Free for a limited time</div>
        <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor='email' className='form-label'>Email:</label>
            </div>
            <div>
                <input
                    type='email'
                    id='email'
                    autoComplete='off'
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className='form-input'
                />
            </div>
            <div>
                <label htmlFor='password' className='form-label'>Password:</label>
            </div>
            <div>
                <input
                    type='password'
                    id='password'
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    className='form-input'
                />
            </div>
            <div>
                <button disabled={(wait || success) ? true : false} className='btn btn-login'>
                    Log in
                </button>
            </div>
        </form>
        <span className='password-forget'><Link to='/forgot-password'>Forgot password?</Link></span>
        <header >
            <h4>
                New user? <Link to='/signup'>Sign up</Link>
            </h4>
        </header>
        {wait? (
        <p>Waiting for confirmation</p>
        ) : (
          null
        ) 
      }
      {success? (
        <p className='success-msg'>Login successful!</p>
        ) : (
          null
        ) 
      }
        <p className='error-msg'>{errMsg}</p>
      </div>
    );
  }
  
  export default Login;
