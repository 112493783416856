import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const InfoGuide = ({open, handleClose}) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          {"Info Guide"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Thank you for choosing Saver Scribe. See how easy it is to get up and running. <br/><br/>
            <strong>Upload</strong> <br/> 
            You can upload either a video or audio file. <br/><br/> 
            <strong>Listen and edit</strong> <br/> 
            Once your recording is transcribed, <strong>you can edit the transcription like a text document</strong>, while listening to the recording if needed. As a bonus, we automatically handle (un)capitalisation. Clicking on a word moves the playback time. <strong>Press “Tab” to play or pause. </strong> <br/><br/>
            <strong>Automatic saving</strong> <br/> 
            <strong>Your transcriptions are automatically saved onto your browser, so long as you are not in private/incognito mode.</strong> Just remember to keep using the same browser. <br/><br/>
            <strong>Export</strong> <br/> 
            Press the “Export” button to download your (edited) transcript into a Word document. <br/><br/>
            <strong>Any questions or comments?</strong> <br/> 
            Feel free to email us at help.saver.scribe@gmail.com. <br/><br/>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InfoGuide;