import logo from '../assets/new name logo.png';

function Streamed() {

    return (
        <div className='streamed-page'>
            <header className='streamed-header'>
                <img alt='Saver Scribe' src={logo} className='logo-small'/>
                <br /> <br />
            </header>
            <div className='streamed-page-text'>
                <h3>Can't find a download button for your streamed recording? Try one of the following options. </h3>
                <p><small>Please note that it is your responsibility to ensure that you have permission to download or capture your streamed recordings. By using one of methods presented on this page, you represent that you have such permissions.</small></p>
                <h3>1. Right-click</h3>
                <p>Try right-clicking on the streamed video to find a download option. You'd be surprised how often that works!</p>
                <h3>2. Streamed video downloader</h3>
                <p>Coming soon. </p>
                <p>But streamed videos can be tough to work with. When all else fails, use our muted recorder. </p>
                <h3>3. Muted recorder</h3>
                <p>Use this handy tool <a href='/record' target='_blank'><strong>here</strong></a> to record a streamed recording while it is being played in another tab. Because the recording is muted, you can leave it on in the background while using your computer for something else. You can even open multiple tabs of our muted recorder tool to record multiple recordings at a time. </p>
                <p>It works on Chrome, Edge and Opera, but not Firefox or Safari.</p>
            </div>
        </div>
    )

}

export default Streamed;    