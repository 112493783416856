import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const InfoGuide = ({open, handleClose}) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          {"What's New?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <strong>17 May 2023: Muted recorder</strong> <br/> 
            We added a handy tool to record a streamed recording while it is being played in another tab. Because the recording is muted, you can leave it on in the background while using your computer for something else.<br/><br/>
            <strong>4 May 2023: How to download streamed recordings</strong> <br/> 
            We added a new page to show you how you can download a streamed recording where you can't see a "download" button. <br/><br/> 
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InfoGuide;