import { Link } from 'react-router-dom';
import logo from '../assets/new name logo.png';
import verify from '../assets/verify email.png';
import { useState, useEffect } from 'react';
import axios from 'axios'

export const API_BASE_URL = '/compute/api'

const signup = '/sign-up/'

const EMAIL_REGEX = /\S+@\S+\.\S+/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/;

const Signup = () => {

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);

  const [password, setPassword] = useState('');
  const [validPassword, setValidPassword] = useState(false);
  const [passwordAlert, setPasswordAlert] = useState(false);

  const [matchPassword, setMatchPassword] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchAlert, setMatchAlert] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [wait, setWait] = useState(false);  
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email])

  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password));
    setValidMatch(password === matchPassword);
  }, [password, matchPassword])

  useEffect(() => {
    setErrMsg('');
  }, [email, password, matchPassword])

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setEmailAlert(true);
    setPasswordAlert(true);
    setMatchAlert(true);

    let reqData = {
      'device': {
        'appVersion': 'string',
        'deviceModel': 'string',
        'deviceToken': 'string',
        'deviceType': 'string',
        'osVersion': 'string',
        'udid': 'string',
        'version': 'string'
      },
      'email': email,
      'firstName': 'string',
      'lastName': 'string',
      'password': password
    }

    if (validEmail && validPassword && validMatch) {
      setWait(true);
      axios.post(API_BASE_URL + signup,
        JSON.stringify(reqData),
        {
            headers: { 'Content-Type': 'application/json' },
        }
      ).then(
        response => {
            console.log(JSON.stringify(response?.data));
            setWait(false);

            if (response?.data.status === 200) {
              setSuccess(true);
            } else if (response?.data.status === 409) { 
              setErrMsg('Email address is already registered');
            } else {
              setErrMsg((JSON.stringify(response?.data.message)).replace(/^"(.*)"$/, '$1'));
            }
        },
        reason => {
        console.error(reason);
        setErrMsg('Login failed');
        setWait(false);
        }
      )
    }
  }

  return (
    <div className='signup-page'>
      <header className='signup-header'>
        <img alt='Saver Scribe' src={logo} className='logo'/>
      </header>
      <form onSubmit={handleSubmit}>
          <div>
              <label htmlFor='email' className='form-label'>Email:</label>
          </div>
          <div>
            <input
                type='email'
                id='email'
                autoComplete='off'
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onBlur={() => setEmailAlert(true)}
                className='form-input'
            />
          </div>
          <div>
            {!validEmail && emailAlert ? (
              <span className='error-email-password'>Please enter a valid email address</span> ) : 
              (null)
            }
          </div>
          <div>
            <label htmlFor='password' className='form-label'>Password:</label>
          </div>
          <div>
            <input
                type='password'
                id='password'
                required
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                onBlur={() => setPasswordAlert(true)}
                className='form-input'
            />
          </div>
          <div>
            <span className='password-hint'>
                - Minimum one uppercase letter <br/>
                - Minimum one lowercase letter <br/>
                - Minimum one digit <br/>
                - 8 to 20 characters <br/>
            </span>
          </div>
          <div>
            {!validPassword && passwordAlert ? (
              <span className='error-email-password'> Please enter a valid password</span> ) : 
              (null)
            }
          </div>
          <div>
            <label htmlFor='confirm-password' className='form-label'>Confirm Password:</label>
          </div>
          <div>
            <input
                type='password'
                id='match-password'
                required
                onChange={(e) => setMatchPassword(e.target.value)}
                value={matchPassword}
                onBlur={() => setMatchAlert(true)}
                className='form-input'
            />
          </div>
          <div>
            {!validMatch && matchAlert ? (
              <span className='error-email-password'>Please ensure that the passwords match</span> ) : 
              (null)
            }
          </div>
          <div>
            <button disabled={(wait || success) ? true : false} className='btn btn-login'>
                  Sign up
            </button>
          </div>
      </form>
      <p>
        <Link to='/login'>
            Return to login
        </Link>
      </p>
      {wait? (
        <p>Waiting for confirmation</p>
        ) : (
          null
        ) 
      }
      {success? (
        <p className='success-msg'>Registration successful! Check your email to confirm your email address. (You may need to check your spam folder.) <br/> <br/>It should look something like this: <br/> <br/> <img alt='Verification email' src={verify} className='logo-small'/></p>
        ) : (
          null
        ) 
      }
      <p className='error-msg'>{errMsg}</p>
    </div>
  );
}
  
export default Signup;
