import logo from '../assets/new name logo.png';

const Error = () => {
  return (
    <div className='signup-page'>
      <header className='signup-header'>
        <img alt='Saver Scribe' src={logo} className='logo-small'/>
      </header>
      <div>
        <h2>Page not found</h2>
      </div>
    </div>
  );
};
export default Error;
