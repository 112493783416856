import { useState, createContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    
    const [uploadedList, setUploadedList] = useState([]);
    const [displayList, setDisplayList] = useState([]);
    const [noDisplayed, setNoDisplayed] = useState();

    return (
        <AuthContext.Provider value={{uploadedList, setUploadedList, displayList, setDisplayList, noDisplayed, setNoDisplayed}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;


