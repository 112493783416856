import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import waitingWheel from '../assets/waiting wheel.gif';
import undo from '../assets/icons/undo.png';
import AuthContext from '../context/AuthProvider';
import axios from 'axios'
import InfoGuide from '../components/InfoGuide';
import { Transforms, createEditor, Node } from 'slate'
import {
  Slate,
  Editable,
  withReact,
} from 'slate-react'
import { withHistory, HistoryEditor } from 'slate-history'
import { saveAs } from 'file-saver';
import { Packer, Document, Paragraph, TextRun } from 'docx';

const API_BASE_URL = '/compute/api'
const transcriptHistory = '/transcript-history/'
const initialSpeed = 1.5;
const maxLength = 40;
const senPerPara = 10;

export const API_AUDIO_URL = 'https://storage.googleapis.com/storage/v1/b/speech-recog-test/o/'
export const altMedia = '?alt=media'

const indexedDB =  window.indexedDB;

var emptyValue = [];

const capWords = ['I ', 'I\'d ', 'I\'ll ', 'I\'m '];

const Edit = () => {

    const token = JSON.parse(localStorage.getItem('SSAuth')).token;

    const id = Number(JSON.parse(sessionStorage.getItem('transcriptData')).id);
    const fileName = JSON.parse(sessionStorage.getItem('transcriptData')).fileName;
    const generatedName = JSON.parse(sessionStorage.getItem('transcriptData')).generatedName;
    const transcriptName = JSON.parse(sessionStorage.getItem('transcriptData')).transcriptName;

    const {uploadedList, setUploadedList, displayList, setDisplayList, noDisplayed, setNoDisplayed} = useContext(AuthContext);

    const [playSpeed, setPlaySpeed] = useState(initialSpeed);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [transLoading, setTransLoading] = useState(false);
    const [audioLoading, setAudioLoading] = useState(false);

    const [justEdited, setJustEdited] = useState(false);

    const [openInfo, setOpenInfo] = useState(false);

    const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    const navigate = useNavigate();

    //*** check progress interval start */
    useEffect(() => {
        setNoDisplayed(displayList.length); 
    }, [displayList]);
    
    useEffect(() => {
    const progressInterval = setInterval(() => {
        const inProgressIndex = displayList.findLastIndex(rec => rec.status === 1);
        //console.log('the progress index is:');
        //console.log(inProgressIndex);

        if (inProgressIndex >= 0) {
        const getInProgress = '?pageNo=' + 1 + '&pageSize=' + (inProgressIndex + 1) + '&search=';

        axios.get(API_BASE_URL + transcriptHistory + getInProgress,
            {
            headers: { 'Content-Type': 'application/json',
            'Authorization': token
            }
            }
        ).then(
            response => {
            let responseArray = response?.data.data.audio;  
            //console.log(JSON.stringify(responseArray));

            const displayListToLoop = displayList;

            for (const recDisplayed of displayListToLoop) {
                if (recDisplayed.status === 1) {
                    const foundInResponse = responseArray.find( recResponse => recResponse.id === recDisplayed.id && recResponse.status === 2 );
                    
                    if (foundInResponse !== undefined) {
                        recDisplayed.status = 2;
                        recDisplayed.transcriptName = foundInResponse.transcriptName;
                    }
                    
                } 
            }
            setDisplayList(displayListToLoop);
            setUploadedList([...displayListToLoop,...uploadedList.slice(noDisplayed)]);
            }, 
            reason => {
            console.error(reason);
            }      
        )  
        }
    }, 30000);
    return () => clearInterval(progressInterval);
    }, [uploadedList, displayList, noDisplayed])
    //*** check progress interval ends */

    //*** grab audio, start */
    useEffect(() => {
        const myURL = API_AUDIO_URL + generatedName + altMedia;
        setAudioLoading(true);
        axios.get(myURL, {responseType: 'blob'} 
        ).then(
            response => {
                //console.log(response);
                const audioURL = URL.createObjectURL(response.data);
                document.getElementById("transAudio").src = audioURL;
                document.getElementById("transAudio").playbackRate = initialSpeed;
                setAudioLoading(false);
            },
            reason => {
                console.error(reason);
                setAudioLoading(false);
            }
        );
            
    }, [])
    //*** grab audio, start */

    // *** getting initial transcript, start
    useEffect (() => {
        setTransLoading(true)

        const request = indexedDB.open('TransDB', 1);

        request.onerror = function (event) {
            console.error('An error occurred:', event)
        }; 

        request.onupgradeneeded =  function () {
            const db = request.result;

            if (!db.objectStoreNames.contains('transcriptions')) {
                db.createObjectStore('transcriptions', {keyPath: 'id'});
                //console.log('A new store has been created')
            }
        }

        request.onsuccess = function () {
            //console.log('Database opened successfully')
            
            const db = request.result;
            const transaction = db.transaction('transcriptions', 'readwrite');
            const store = transaction.objectStore('transcriptions');

            const idQuery = store.get(id); 
            
            idQuery.onsuccess = function () {
                if (idQuery.result === undefined) {
                    // *** This is where I put the axios get for the transcription
                    console.log('Fetching transcription from cloud')
                    axios.get(API_AUDIO_URL + transcriptName + altMedia
                    ).then(
                        response => {
                            var initialValue = [];
                            var respWords = [];
                            var respWordsText = [];
                            var endingInFSIndices = [];
                            var endingInQMIndices = [];
                            var endOfSentenceIndices = [];
                            var slicePositions = [];

                            respWords = response?.data.words;
                    
                            respWords.forEach((wordObj) => {
                            wordObj.word = wordObj.word.concat(' ')
                            wordObj.text = wordObj.word;
                            delete wordObj.word
                            });
                            
                            // now, we split up the transcription into "paragraphs"
                            respWordsText = respWords.map( wordObj => wordObj.text )
                            
                            for (let i = 0; i < respWordsText.length; i++) {
                              if ( respWordsText[i].endsWith('. ') ) {
                                endingInFSIndices.push(i)
                              }
                            }
                        
                            for (let i = 0; i < respWordsText.length; i++) {
                              if ( respWordsText[i].endsWith('? ') ) {
                                endingInQMIndices.push(i)
                              }
                            }
                        
                            endOfSentenceIndices = endingInFSIndices.concat(endingInQMIndices);
                            endOfSentenceIndices.sort(function(a, b){return a - b});
                        
                            
                            var numberOfWords = respWords.length;
                        
                            slicePositions.push(0);
                        
                            for (let i = senPerPara - 1; i < endOfSentenceIndices.length; i += senPerPara ) {
                              slicePositions.push(endOfSentenceIndices[i]+1);
                            }
                        
                            if ( slicePositions[slicePositions.length - 1] < numberOfWords ) {
                              slicePositions.push(numberOfWords);
                            }
                        
                            // now adding paragraphs to the initialValue 
                        
                            for ( let i = 0; i < slicePositions.length - 1; i++ ) {
                              const childrenText = respWords.slice(slicePositions[i], slicePositions[i+1])
                        
                              initialValue.push(
                                {
                                  type: 'paragraph',
                                  children: childrenText
                                }
                              )
                              
                              var splitWord = {...respWords[slicePositions[i+1]-1]};
                              splitWord.text = '';
                        
                              initialValue.push(
                                {
                                  type: 'paragraph',
                                  children: [splitWord]
                                }
                              )
                              
                            }
                            
                            // The initialValue should be completed now

                            Transforms.removeNodes(
                                editor,
                                { at: 1 }
                            );
                           
                            Transforms.insertNodes(
                                editor,
                                initialValue
                            );
                            //console.log(JSON.stringify(editor))
                            //store.put({id: id, transcription: initialValue}); // don't do this - rely on regular save function
                            //console.log('A transcription was added to the store');
                            setHasLoaded(true)
                            setTransLoading(false);
                            setJustEdited(true);
                        },
                        reason => {
                          console.error(reason);
                        }
                    )   

                } else {
                    const initialValue = idQuery.result.transcription;
                    //console.log('The transcription was found in the store');
                    //console.log(JSON.stringify(initialValue))
                    Transforms.removeNodes(
                        editor,
                        { at: 1 }
                    );
                    Transforms.insertNodes(
                        editor,
                        initialValue
                    );
                    setHasLoaded(true)
                    setTransLoading(false);
                }
            }

            transaction.oncomplete = function () {
                db.close();
            }
        }
        
    }, [])
    // *** getting initial transcript, end
    
    //*** Play shortcut key, start
    const onTabDown = (keyDownEvent) => {
        if (keyDownEvent.key === 'Tab') {
            keyDownEvent.preventDefault();
            if (document.getElementById("transAudio").paused) {
                document.getElementById("transAudio").play()
            } else if (!document.getElementById("transAudio").paused) {
                document.getElementById("transAudio").pause()
            }
        } else if (keyDownEvent.key !== 'Tab') {
            if (!document.getElementById("transAudio").paused) {
                keyDownEvent.preventDefault();
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onTabDown);

        return () => {window.removeEventListener('keydown', onTabDown)}
    }, [])
    //*** Play shortcut, end

    // *** the handle save function, start
    async function handleSave() {
        const request = indexedDB.open('TransDB', 1);

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('transcriptions', 'readwrite');
            const store = transaction.objectStore('transcriptions');

            store.put({id: id, transcription: editor.children});
            
            transaction.oncomplete = function () {
                console.log('Transcription is saved into the DB, thanks to the async function')
                db.close();
            }
        }
    }
    // *** the handle save function, end

    // *** regular save, start
    useEffect(() => {
        const saveInterval = setInterval(() => {
            if (justEdited) {
                //console.log('The editor has been edited');
                handleSave().then(() => {
                    //console.log('The edited transcript has been saved, thanks to the interval')
                    setJustEdited(false);
                })
            }
        }, 30000);
        return () => clearInterval(saveInterval);
        }, [justEdited])        
    // *** regular save, end

    // *** unsaved, start
    useEffect(() => {
        const onBeforeUnload = (e) => {
            if (justEdited) {
                e.preventDefault();
                e.returnValue = 'You have unsaved changes. Are you sure you want to leave? Return home to save.';
            }
        }
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, [justEdited])
    // *** unsaved, end

    // *** configure editor, start
    const handleOnChange = () => {
        const isAstChange = editor.operations.some(
            op => 'set_selection' !== op.type  && 'insert_node' !== op.type && 'remove_node' !== op.type
        )
        if (isAstChange) {
            setJustEdited(true);
        }
    }

    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.highlight) {
          children = <span style={{background: 'lightgray'}}>{children}</span>
        }
        return <span {...attributes}>{children}</span>
    }
    // *** configer editor, end

    //*** */ automatic (un)capitalisation, start
    const handleOnKeyDown = (keyDownevent) => {
        if (keyDownevent.key === '.' || keyDownevent.key === '?' || keyDownevent.key === '!' ) {
            const puncPointBeforeObj = editor.selection.focus;
            const puncWord = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]].text;
            const nextChar = puncWord.charAt(puncPointBeforeObj.offset)
            //console.log(JSON.stringify(puncWord,nextChar))
            if (nextChar === ' ') {
                const nextWordFirstChar = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]+1]?.text.charAt(0);
                //console.log(nextWordFirstChar)
                if (nextWordFirstChar !== undefined) {
                    Transforms.delete(editor, {
                        at: {
                            anchor: {path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 0 },
                            focus: {path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 1 }
                        },
                    }) 
                
                    Transforms.insertText(editor,
                        nextWordFirstChar.toUpperCase(), {
                        at: {
                            path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 0 
                        }
                    })
                }      
            }
        } else if (keyDownevent.key === 'Backspace') {
            const puncPointBeforeObj = editor.selection.focus;
            const puncWord = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]].text;
            const lastChar = puncWord.charAt(puncPointBeforeObj.offset-1);
            const nextWord = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]+1]?.text ;
            //console.log(JSON.stringify(puncWord,lastChar,nextWord))
            if ( (lastChar === '.' || lastChar === '?' || lastChar === '!') && ( capWords.includes(nextWord) === false ) ) {
                const nextWordFirstChar = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]+1]?.text.charAt(0);
                //console.log(nextWordFirstChar)
                if (nextWordFirstChar !== undefined) {
                    Transforms.delete(editor, {
                        at: {
                            anchor: {path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 0 },
                            focus: {path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 1 }
                        },
                    }) 
                
                    Transforms.insertText(editor,
                        nextWordFirstChar.toLowerCase(), {
                        at: {
                            path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 0 
                        }
                    })
                }
            }

        } else if (keyDownevent.key === 'Delete') {
            const puncPointBeforeObj = editor.selection.focus;
            const puncWord = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]].text;
            const nextChar = puncWord.charAt(puncPointBeforeObj.offset);
            const nextWord = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]+1]?.text;
            //console.log(JSON.stringify(puncWord,nextChar,nextWord))
            if ( (nextChar === '.' || nextChar === '?' || nextChar === '!') && ( capWords.includes(nextWord) === false ) ) {
                const nextWordFirstChar = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]+1]?.text.charAt(0);
                //console.log(nextWordFirstChar)
                if (nextWordFirstChar !== undefined) {
                    Transforms.delete(editor, {
                        at: {
                            anchor: {path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 0 },
                            focus: {path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 1 }
                        },
                    }) 
                
                    Transforms.insertText(editor,
                        nextWordFirstChar.toLowerCase(), {
                        at: {
                            path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]+ 1], offset: 0 
                        }
                    })
                }
            }
        } else if (keyDownevent.key === ' ') {
            const puncPointBeforeObj = editor.selection.focus;
            const puncWord = editor.children[puncPointBeforeObj.path[0]].children[puncPointBeforeObj.path[1]].text;
            const lastChar = puncWord.charAt(puncPointBeforeObj.offset-1);
            const lastLastChar = puncWord.charAt(puncPointBeforeObj.offset-2);
            
            if ( lastChar === 'i' && ( lastLastChar === ' ' || lastLastChar === '' ) ) {
                Transforms.delete(editor, {
                    at: {
                        anchor: {path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]], offset: puncPointBeforeObj.offset-1 },
                        focus: {path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]], offset: puncPointBeforeObj.offset }
                    },
                }) 
            
                Transforms.insertText(editor,
                    'I', {
                    at: {
                        path: [puncPointBeforeObj.path[0], puncPointBeforeObj.path[1]], offset: puncPointBeforeObj.offset-1 
                    }
                })
            }
        }
    }
    //*** */ automatic (un)capitalisation, end

    //*** click, start */
    const handleClickOnText = () => {
        const focusParaIndex = editor.selection.focus.path[0];
        const focusWordIndex = editor.selection.focus.path[1];
        const focusStartTime = editor.children[focusParaIndex].children[focusWordIndex].startTime;
        document.getElementById("transAudio").currentTime = focusStartTime - 0.2;
    }
    //*** click, end */ 

    //*** right-click, start */
    const handleContextMenu = (rightClickEvent) => {
        rightClickEvent.preventDefault();
        //const focusObj = editor.selection.focus;
        //console.log(focusObj);
        //console.log('the position is: ', rightClickEvent.pageX, rightClickEvent.pageY )
    }
    //*** right-click, end */

    //***  header bar, start */
    const handleBack = () => {
        if (justEdited) {
            handleSave().then(() => {
                //console.log('The edited transcript has been saved, thanks to the handleBack');
                setJustEdited(false);
            }).then(() => {
                navigate('/home');
            })
        } else {
            navigate('/home');
        }
        //console.log('you have just gone back to the home page')
    }
    
    const handleUndo = () => {
        HistoryEditor.undo(editor);
    }

    const handleClickOpenInfo = () => {
        setOpenInfo(true);
    }

    const handleCloseInfo = () => {
        setOpenInfo(false);
    }

    const nameToDisplay = fileName.slice(0,maxLength);

    const handlePlaySpeed = (e) => {
        document.getElementById("transAudio").playbackRate = (e.target.value);
        setPlaySpeed(e.target.value);
    }

    const handleExport = () => {
        
        const fileNameNoExt = fileName.substring(0,fileName.lastIndexOf('.')).replaceAll('.','');
        const nodes = editor.children;
        const serialized = nodes.map(n => Node.string(n)).join('\n');
        const textRuns = serialized.split('\n').map(line => new TextRun({break: 1, text: line, font: 'Arial'}))

        const doc = new Document({

            sections: [{
                children: [
                    new Paragraph({
                        children: textRuns,
                    })
                ],
            }]
        });

        Packer.toBlob(doc).then( blob => {
            saveAs(blob, fileNameNoExt);
        })
    }
    //*** header bar, end */

    return (
        <div>
            <div className='edit-header'>
                <div className='edit-header-left'>
                    <button className='btn edit-header-btn edit-wider-btn' onClick={handleBack}>{'< Home'}</button>
                    <button className='btn edit-header-btn' onClick={handleUndo} ><img alt='Undo' className='edit-icon' src={undo}/></button>
                    <button className='btn edit-header-btn' onClick={handleClickOpenInfo}><img className='edit-icon'/>Info</button>
                </div>
                <div className='edit-header-center'>
                    <span>{nameToDisplay}</span>
                </div>
                <div className='edit-header-left'>
                    <select className='edit-header-select' value={playSpeed} onChange={handlePlaySpeed}>s
                        <option value={0.5}>0.5x</option>
                        <option value={1}>1.0x</option>
                        <option value={1.5}>1.5x</option>
                        <option value={2}>2.0x</option>
                    </select>
                    <audio id='transAudio' controls controlsList="nodownload noplaybackrate" />
                    <div className='edit-header-add-text'>Hint: press Tab to play</div>
                </div>
                <div className='edit-header-right'>
                    <button className='btn edit-header-btn edit-wider-btn' onClick={handleExport}>Export</button>
                </div>
            </div>
            <div className='edit-top-margin' />
            <div className='edit-loading' hidden={!transLoading}><span>Loading transcription <img alt='(please wait)' src={waitingWheel} className='waiting-wheel'/></span></div>
            <div className='edit-loading' hidden={!audioLoading}><span>Loading audio <img alt='(please wait)' src={waitingWheel} className='waiting-wheel'/></span></div>
            <div className='edit-transcript' id='slateDiv' hidden={!hasLoaded} >
                <Slate editor={editor} value={emptyValue} onChange={handleOnChange} >
                    <Editable onClick={handleClickOnText} renderLeaf={renderLeaf} onKeyDown={handleOnKeyDown} spellCheck='true' autoCorrect='true' autoCapitalize='true' onContextMenu={handleContextMenu} />
                </Slate>
            </div> 
            <InfoGuide open={openInfo} handleClose={handleCloseInfo} />
        </div>
    )

} 

export default Edit;