
const DisplayBoxes = ({displayListToRender, deleteFunction, selectRecFunction}) => {

    const getRecordingName = (recordingFileName) => {
        const maxLength = 75;
        return recordingFileName.slice(0,maxLength);
    }

    const getAudioLength = (seconds) => {
        const h = parseInt(seconds / (60 * 60));
        const m = parseInt((seconds % (60 * 60)) / 60);
        const s = parseInt(seconds % 60);
        return (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
    }

    const getDateTime = (timestamp) => {
        return new Date(timestamp).toLocaleString();
    }

    const boxStyleFromStatus = (status) => {
        if (status === 2) {
            const boxStyle = 'recording-box';
            return boxStyle;
        } else if (status === 1) {
            const boxStyle = 'recording-box-in-progress';
            return boxStyle;
        }
        
    }

    return (
        <div className='recording-section'>
            {displayListToRender.map((recordingDetails) => (
                <div className={boxStyleFromStatus(recordingDetails.status)} key={recordingDetails.id} onClick={() => selectRecFunction(recordingDetails)}>
                    <button id={'del'+recordingDetails.id.toString()} className='recording-delete' onClick={(e) => deleteFunction(e, recordingDetails.id)}>X</button>
                    <div className='recording-title'>{getRecordingName(recordingDetails.fileName)}</div>
                    <div className='recording-length'><br/></div>
                    <div className='recording-date'><span>Upload time: {getDateTime(recordingDetails.createdAt)}</span> { (recordingDetails.status === 1) ? <span className="recording-progress">... Transcription in progress</span> : (null) }</div>
                </div>
            ))}
        </div>                
    );

};

export default DisplayBoxes;
